@import '../../includes/all';

$box-bg: white;
$box-heading-color: $theme-heading;
$box-btn-color: #4a4a4a;

.box-container > div {
  &.buttons > button,
  &:not(.buttons) {
    @include boxshadow;
    background-color: $box-bg;
  }

  &:not(.buttons) {
    margin-bottom: $v-gutter;
  }

  &:not(.super-container),
  &.super-container > div {
    &:not(.buttons) {
      h3 {
        font-size: 23px;
        padding: 10px $h-gutter;
        margin: 0;

        &,
        & span {
          @include font-semibold;
          color: $box-heading-color;
        }

        span.message {
          font-size: 14px;
        }
      }

       > div {
        @include clearfix;
        padding: 0 $h-gutter $v-gutter;
      }
    }

    &.buttons {
      height: 47px;

       > button {
        border: 0;
        width: 200px;
        height: 37px;
        margin-right: 10px;
        font-size: 16px;
        color: $box-btn-color;
        vertical-align: top;

        &:focus {
          outline: none;
        }

        &.active {
          padding-bottom: 11px;
          height: 100%;
          position: relative;
          z-index: -1;
        }

        &:hover {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
