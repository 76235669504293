@import '../../includes/all';

$box-bg: white;
$box-heading-color: $theme-heading;
$box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);

.content-box {
  background-color: $box-bg;

  h3 {
    font-size: 23px;
    padding: 10px $h-gutter;
    margin: 0;

    &,
    & span {
      @include font-semibold;
      color: $box-heading-color;
    }

    span.message {
      font-size: 14px;
    }
  }

   > div {
    @include clearfix;
    padding: 0 $h-gutter $v-gutter;
  }
}

.content-box {
  box-shadow: $box-shadow;
  margin-bottom: $v-gutter;
}
