@import '../../includes/all';

html.in-frame {
  // Workaround for scrolling bug in Chrome 66
  -webkit-transform: translate3d(0, 0, 0);
}

body {
  background: no-repeat fixed center;
  background-size: cover;
  background-image: url($booking-bg);
}

#loading-box {
  max-width: 480px;
  margin: 15px auto;
  padding: 0 10px;
}

#content {
  max-width: $max-desktop-width + 20px;
  margin: 0 auto 50px;
  padding: 10px;
}

#content-wrapper {
  position: relative;
  width: 100%;

  $sidebar-width: 320px;

   > aside {
    width: $sidebar-width;
  }

   > main.split,
   > aside {
    display: inline-block;
    vertical-align: top;

    @include mobile {
      display: block;
      width: 100%;
    }
  }

   > main {
    position: relative;

    @include desktop {
      &.split {
        margin-right: $h-gutter;
        width: 620px; // Fallback
        width: calc(100% - 340px);
      }
    }
  }
}
